<template>
  <div>
        <ul class="ul_direction">
                <li class="li_position" :class="{ is_valid: contains_eight_characters }">{{ $t('eightCharacters') }}</li>
                <li class="li_position" :class="{ is_valid: contains_number }">{{ $t('containsNumber') }}</li>
                <li class="li_position" :class="{ is_valid: contains_uppercase }">{{ $t('containsUppercase') }}</li>
                <li class="li_position" :class="{ is_valid: contains_lowercase }">{{ $t('containsLowercase') }}</li>
                <li class="li_position" :class="{ is_valid: contains_special_character }">{{ $t('containsSpecialCharacter') }}</li>
                <!-- <li class="li_position" :class="{ is_valid: not_contains_userName }">{{ $t('notContainsUserName') }}</li> -->
          </ul>
          <!-- <div class="checkmark_container checkmark_dir" :class="{ show_checkmark: valid_password }">
          <svg width="50%" height="50%" viewBox="0 0 140 100">
          <path class="checkmark" :class="{ checked: valid_password }" d="M10,50 l25,40 l95,-70" />
          </svg>
          </div> -->
  </div>
</template>

<script>

export default {
  props: {
    password: {
      type: String,
      default: '',
    },
    validPassword: {
       type: Boolean,
       default: false,
    }
  },
    emits: ['validPassword'],
  data() {
    return {
      password_length: 0,
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_lowercase: false,
      contains_special_character: false,
      valid_password: false,
      not_contains_userName: true
    };
  },
  computed: {
  },
  watch: {
    password() {
      this.checkPassword();
    },
  },
  methods: {
         checkPassword() {
      this.password_length = this.password.length;
      const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
      if (this.password_length > 7) {
        this.contains_eight_characters = true;
      } else {
        this.contains_eight_characters = false;
        }
      this.contains_number = /\d/.test(this.password);
      this.contains_uppercase = /[A-Z]/.test(this.password);
      this.contains_lowercase = /[a-z]/.test(this.password);
      this.contains_special_character = format.test(this.password);
      // this.not_contains_userName = !this.password.toLowerCase().includes(this.profile.userName.toLowerCase())
      if (this.contains_eight_characters === true && this.contains_special_character === true && this.contains_uppercase === true && this.contains_lowercase === true && this.contains_number === true && this.not_contains_userName === true) {
        this.valid_password = true;
      } else {
        this.valid_password = false;
      }
      this.$emit('validPassword', this.valid_password);
  },
},
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/password-validation-style.scss';
</style>
